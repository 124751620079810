.LangSelector {
    padding: 8px;

    border-radius: 50%;
    border: none;

    background-color: #15e6d0;
    color: white;

    transition:
        color .1s,
        background-color .1s;
}

.LangSelector:hover {
    background-color: #12c4b2;
}

.LangSelector:active {
    background-color: #0fa294;
}
