.Circle {
    border-radius: 50%;

    font-size: 16px;

    width: 1em;
    height: 1em;

    background-color: white;
    border: thin solid black
}
