.DropZone {
    width: 100%;
    height: 100px;

    background-color: #EEE;
    color: #888;

    border: thin dashed #888;
    border-radius: 4px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    transition: background-color .1s;
}

.hover {
    background-color: #DDD;
    cursor: copy;
}
