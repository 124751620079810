/* InviteTable */

.InviteTable {
    white-space: nowrap;
    font-size: 14px;
    font-style: italic;

    display: grid;
    grid-template-columns: repeat(4, min-content);
    grid-auto-rows: min-content;
}

.InviteTable > * {
    padding: 8px;
}

/* background */

.background {
    grid-column: 1/5;
    background-color: #E8F9E0;
    transition: background-color .1s;
}

.background:hover,
.background:has(+ *:hover),
.background:has(+ * + *:hover),
.background:has(+ * + * + *:hover),
.background:has(+ * + * + * + *:hover),
.background:has(+ * + * + * + * + *:hover),
.background:has(+ * + * + * + * + * + *:hover),
.background:has(+ * + * + * + * + * + * + *:hover) {
    background-color: #DCEDD5;
}

.background:active,
.background:has(+ *:active),
.background:has(+ * + *:active),
.background:has(+ * + * + *:active),
.background:has(+ * + * + * + *:active),
.background:has(+ * + * + * + * + *:active),
.background:has(+ * + * + * + * + * + *:active),
.background:has(+ * + * + * + * + * + * + *:active) {
    background-color: #D3E2CD;
}

.expiredBackground {
    composes: background;
    background-color: #EEE !important;
}

/* date */

.date {
    grid-column: 1;
}

/* company */

.company {
    grid-column: 2;
    padding: 8px 16px;
}

/* messageTarget */

.messageTarget {
    grid-column: 3;
}

/* role */

.role {
    font-size: 16px;
    font-style: normal;
}

/* name */

.name {
    grid-column: 3/5;
}

/* buttons */

.buttons {
    grid-column: 4;
}

/* creator */

.creator {
    grid-column: 1/3;
}

/* expiresIn */

.expiresIn {
    grid-column: 3/5;
    color: red;
    text-align: right;
}

/* expired */

.expired {
    color: gray;
}

/* timer */

.timer {
    display: inline-block;
    min-width: 60px;
}

/* gap */

.gap {
    grid-column: 1/5;
}
