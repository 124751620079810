/* TextArea */

.TextArea {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 4px;

    font-size: 16px;
    line-height: 1;
}

/* :readonly */

.ReadonlyTextArea {
    composes: TextArea;
}

.ReadonlyTextArea, .ReadonlyTextArea * {
    cursor: not-allowed;
}

/* :loading */

.LoadingTextArea {
    composes: ReadonlyTextArea;
}

.LoadingTextArea, .LoadingTextArea * {
    cursor: wait;
}

/* htmlTextAreaWrapper */

.htmlTextAreaWrapper {
    position: relative;

    width: 100%;
    min-height: 32px;

    flex-grow: 1;
}

/* htmlTextArea */

.htmlTextArea {
    width: 100%;
    height: 100%;

    padding: 8px;
    font-size: 16px;

    border-radius: 4px;
    border: thin solid #CCC;

    background-color: white;
    color: black;

    transition:
        color .1s,
        background-color .1s,
        border-color .1s;
}

/* :hover */

.htmlTextArea:hover {
    border-color: #999;
}

/* :focus */

.htmlTextArea:focus {
    border-color: #4C739E;
    outline: none;
}

/* :disabled */

.htmlTextArea:disabled {
    background-color: #E6E6E6;
    border-color: #E6E6E6;
    color: #808080;
}

/* :readonly */

.ReadonlyTextArea .htmlTextArea {
    background-color: white;
    border-color: #E6E6E6;
    color: black;
}

/* :invalid */

.invalidHtmlTextArea {
    composes: htmlTextArea;
    border: thin solid red !important;
}

/* max */

.max {
    position: absolute;
    right: 8px;
    bottom: 8px;

    user-select: none;

    color: #999;
}
