/* Page */

.Page {
    overflow: hidden;

    min-height: 100vh;
    max-width: 100vw;

    display: grid;
    grid-template-columns: min-content minmax(auto, 100%);
    grid-template-rows: min-content auto min-content;
}

/* header */

.header {
    grid-column: 1/3;
    grid-row: 1;

    padding: 16px;
}

/* menu */

.menu {
    grid-column: 1;
    grid-row: 2;

    padding-left: 4px;
}

/* main */

.main {
    grid-column: 2;
    grid-row: 2;

    display: flex;
    flex-direction: column;
    gap: 16px;

    overflow: hidden;

    padding: 0 16px 0 4px;

    transition: all .4s;
}

.authMain {
    composes: main;
    grid-column: 1/3;
}

/* mainPart */

.mainPart {
    position: relative;
    overflow: auto;
}

/* title */

.title {
    composes: mainPart;
    font-size: 20px;
    color: #305673;
    height: 32px;
    white-space: nowrap;
}

/* content */

.content {
    composes: mainPart;
    flex-grow: 1;
}

/* footer */

.footer {
    grid-column: 1/3;
    grid-row: 3;

    padding: 16px;
}
