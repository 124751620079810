/* flex */

.Center-flex {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

/* absolute */

.Center-absolute {
    width: fit-content;
    height: fit-content;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

/* absolute-fill */

.Center-absolute-fill {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
}

.Center-absolute-fill > * {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
