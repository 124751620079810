.ErrorBlock {
    text-align: center;

    width: 100%;
    padding: 8px;

    border: thin solid #D11313;
    border-radius: 4px;

    background-color: #FDF3F3;
}
