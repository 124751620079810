/* Table */

.Table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
}

.Table th {
    background-color: #DFECFA;
    color: #333;

    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;

    padding: 8px;
}

.Table td {
    padding: 8px;

    font-size: 14px;
    white-space: nowrap;

    cursor: default;
}

.Table th,
.Table td {
    border: thin solid #F7FAFE;
    vertical-align: top;
}

.Table tr {
    transition: background-color .1s;
}

.Table tr:nth-child(2n) {
    background-color: #F9FAFB;
}

.Table tr:hover {
    background-color: #E9EAEB !important;
}
