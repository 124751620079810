/* CheckBox */

.CheckBox {
    position: relative;

    display: inline-flex;
    align-items: center;
    gap: 8px;

    white-space: nowrap;
}

/* :readonly */

.ReadonlyCheckBox {
    composes: CheckBox;
}

.ReadonlyCheckBox, .ReadonlyCheckBox * {
    cursor: not-allowed;
}

/* :loading */

.LoadingCheckBox {
    composes: ReadonlyCheckBox;
}

.LoadingCheckBox, .LoadingCheckBox * {
    cursor: wait;
}

/* htmlInput */

.htmlInput {
    width: 22px;
    height: 22px;

    border: none;

    opacity: 0;
}

/* input */

.input {
    position: absolute;
    left: 0;
    top: 0;

    width: 20px;
    height: 20px;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    background-color: white;

    border: thin solid #CCC;
    border-radius: 4px;

    pointer-events: none;

    transition:
        color .1s,
        background-color .1s,
        border-color .1s;
}

/* :hover */

.htmlInput:hover + .input {
    border-color: #999;
}

/* :checked */

.htmlInput:checked + .input {
    border-color: #4C739E;
    background-color: #4C739E;
}

.htmlInput:checked:hover + .input {
    border-color: #3f6084;
}

/* :disabled */

.htmlInput:disabled + .input {
    background-color: #CCC !important;
    border-color: #CCC !important;
}
