/* LinkedText */

.LinkedText a {
    color: #2D6FCC;
    text-decoration-color: #2D6FCC;

    transition:
        color .1s,
        text-decoration-color .1s;
}

.LinkedText a:hover {
    color: #1f4c8b;
    text-decoration-color: #1f4c8b;
}

.LinkedText a:active {
    color: #24579f;
    text-decoration-color: #24579f;
}
