/* childrenWrapper */

.childrenWrapper {
    position: relative;

    width: 100%;
    min-height: 32px;

    font-size: 16px;
    line-height: 1;
}

/* children */

.children {
    display: flex;
    justify-content: space-between;
    gap: 8px;

    overflow: auto;

    width: 100%;
    height: 100%;
    min-height: 32px;

    padding: 8px;

    border-radius: 4px;
    border: thin solid #E6E6E6;

    background-color: white;
    color: black;
}

/* icon */

.icon {
    align-self: center;
    display: flex;
    align-items: center;
}

/* left */

.left {
    position: absolute;
    left: -4px;
    top: 50%;
    transform: translate(-100%, -50%);
}

/* right */

.right {
    position: absolute;
    right: -4px;
    top: 50%;
    transform: translate(100%, -50%);
}
