/* Logo */

.Logo {
    width: fit-content;
    height: fit-content;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}

/* image */

.image {
    height: 32px;
}
