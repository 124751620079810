@import-normalize;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
}

html {
    font-size: 16px;
}

body {
    background-color: white;
    color: black;
}

@font-face {
    font-family: "Montserrat";
    src:
        local("Montserrat")
        url(./fonts/Montserrat/Montserrat-VariableFont_wght.ttf);
}
