/* block */

.block {
    border-radius: 4px;
    border: thin solid #CCC;

    background-color: white;
    color: black;

    transition: border-color .1s;
}

.block:hover {
    border-color: #999;
}

/* DropDown */

.DropDown {
    composes: block;

    width: 100%;
    height: 32px;

    padding: 0 8px;

    font-size: 16px;
    white-space: nowrap;
}

.DropDown:active {
    border-color: #4C739E;
    outline: none;
}

/* items */

.items {
    composes: block;

    position: fixed;
    z-index: 1;

    padding: 8px;

    max-height: min(80vh, 300px);
    overflow: auto;

    animation-name: appearance;
    animation-duration: .1s;
}

@keyframes appearance {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
