/* Notifications */

.Notifications {
    position: relative;
}

/* count */

.count {
    position: absolute;
    left: calc(50% + 2px);
    top: -2px;

    display: flex;
    align-items: flex-start;
}

/* list */

.list {
    position: fixed;
    z-index: 10;

    max-width: calc(100vw - 16px);

    border-radius: 4px;
}
