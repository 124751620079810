.layout {
    max-height: 80vh;

    overflow: scroll;

    display: grid;
    grid-auto-columns: 1fr;
    grid-template-rows: min-content;
    gap: 16px;
}
