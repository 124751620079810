/* table */

.table {
    border-collapse: collapse;
    font-size: 14px;
}

.table td {
    /* border: thin solid black; */
    position: relative;
    padding: 0 16px;
    height: 48px;
}

/* smallText */

.smallText {
    font-size: 14px;
}

/* normalText */

.normalText {
    font-size: 16px;
}

/* bigText */

.bigText {
    font-size: 18px;
}

/* user */

.user {
    background-color: #E8F9E0;
}

/* role */

.role {
    composes: bigText;
    composes: user;
}

.role > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* agent */

.agent {
    background-color: #F9FCC2;
}

.blankAgent {
    composes: agent;
    text-align: center;
}

/* rights */

.rights {
    background-color: #ffeadd;
}

/* firstRow */

.firstRow {
    composes: bigText;
    background-color: #F5F4F0;
    transition: background-color .2s;
    cursor: pointer;
}

.firstRow:hover,
.firstRow:has(+ *:hover),
.firstRow:has(+ * + *:hover),
.firstRow:has(+ * + * + *:hover),
.firstRow:has(+ * + * + * + *:hover) {
    background-color: #E2E1DE;
}

.firstRow:active,
.firstRow:has(+ *:active),
.firstRow:has(+ * + *:active),
.firstRow:has(+ * + * + *:active),
.firstRow:has(+ * + * + * + *:active) {
    background-color: #CBCAC8;
}

/* gap */

.gap {
    height: 16px !important;
}
