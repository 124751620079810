/* layout */

.layout {
    display: grid;
    align-items: center;
    justify-items: center;
    gap: 8px;
}

.verticalLayout {
    composes: layout;
    grid-template-columns: min-content min-content;
    grid-auto-rows: min-content;
}

.horizontalLayout {
    composes: layout;
    grid-template-rows: 60px min-content;
    grid-auto-columns: min-content;
}

/* phase */

.phase {
    display: flex;
    align-items: center;
    justify-content: center;

    width: fit-content;

    border: thin solid transparent;

    transition: all .5s;
}

.phase-small {
    composes: phase;

    padding: 4px;

    min-width: 30px;
    height: 30px;

    border-radius: 15px;
}

.phase-normal {
    composes: phase;

    padding: 8px;

    min-width: 40px;
    height: 40px;

    border-radius: 20px;
}

.phase-big {
    composes: phase;

    padding: 12px;

    min-width: 60px;
    height: 60px;

    border-radius: 30px;
}

/* phaseContent */

.phaseContent {
    white-space: nowrap;
}

/* phaseLabelWrapper */

.phaseLabelWrapper {
    justify-self: start;
}

/* phaseLabel */

.phaseLabel {
    white-space: nowrap;
}

.verticalLayout .phaseLabel {
    padding-left: 8px;
}

/* separator */

.separator {
    border: 2px dashed #59778F;
}

.verticalLayout .separator {
    width: 2px;
    height: 32px;
}

.horizontalLayout .separator {
    width: 48px;
    height: 2px;
}

.finishedSeparator {
    composes: separator;
    border-style: solid;
    border-radius: 4px;
}

/* label */

.label {
    font-size: 22px;
    white-space: pre;
}
