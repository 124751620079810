.Loading, .Loading * {
    cursor: wait;
}

.FixedLoading {
    composes: Loading;

    position: fixed;
    left: 0;
    top: 0;

    width: 100vw;
    height: 100vh;
}
