/* table */

.table {
    font-size: 14px;
    border-collapse: collapse;
}

.table td {
    /* border: thin solid black; */
    padding: 0 16px;
    height: 40px;
}

/* provider */

.provider {
    background-color: #E8F9E0;
}

/* buttons */

.buttons {
    display: flex;
    flex-direction: column;
}

/* gap */

.gap {
    height: 16px !important;
}
