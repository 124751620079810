/* bar */

.bar {
    height: 4px;
    border-radius: 2px;
}

/* outer */

.outer {
    composes: bar;

    width: 100%;

    background-color: #D9D9D9;
}

/* inner */

.inner {
    composes: bar;

    background-color: #305673;

    transition: width .4s;
}
