/* dayOfWeek */

.dayOfWeek {
    width: 100%;
    margin-left: 8px;
    font-size: 12px;
}

/* dayOfWeekName */

.dayOfWeekName {
    color: #999;
}

/* table */

.table {
    text-align: left;

    width: 100%;

    border: thin solid #D6D6D6;
    border-collapse: collapse;

    margin-bottom: 20px;
}

/* hour */

.hour {
    transform: translateY(50%);

    width: 32px;

    font-size: 8px;
    text-align: center;

    color: #999;
}

/* transfersCell */

.transfersCell {
    border: thin solid #D6D6D6;
}

/* transfers */

.transfers {
    padding: 4px;
    min-height: 32px;
    font-size: 12px;
}
