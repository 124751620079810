/* SectionLinkList */

.SectionLinkList {
    padding: 12px 4px;
    min-width: 72px;
    width: 256px;

    background-color: #F5F4F0;
    border-radius: 16px;

    animation-duration: .4s;
    animation-fill-mode: forwards;

    transition: all .4s;
}

.AppearingSectionLinkList {
    composes: SectionLinkList;
    animation-name: appear;
}

.HidingSectionLinkList {
    composes: SectionLinkList;
    background-color: transparent;
    animation-name: hide;
}

.HiddenSectionLinkList {
    composes: SectionLinkList;
    background-color: transparent;
    width: 72px;
}

/* appear */

@keyframes appear {
    from {
        width: 72px;
    }

    to {
        width: 256px;
    }
}

/* hide */

@keyframes hide {
    from {
        width: 256px;
    }

    to {
        width: 72px;
    }
}

/* linkGroups */

.linkGroups {
    overflow: hidden;
    width: 100%;
}
