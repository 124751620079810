/* Select */

.Select {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 4px;

    font-size: 16px;
}

/* :readonly */

.ReadonlySelect {
    composes: Select;
}

.ReadonlySelect, .ReadonlySelect * {
    cursor: not-allowed;
}

/* :loading */

.LoadingSelect {
    composes: ReadonlySelect;
}

.LoadingSelect, .LoadingSelect * {
    cursor: wait;
}

/* htmlSelectContainer */

.htmlSelectContainer {
    position: relative;

    width: 100%;
    height: 32px;
}

/* htmlSelect */

.htmlSelect {
    position: relative;
    z-index: 1;
    opacity: 0;

    width: 100%;
    height: 100%;

    padding: 8px;
}

/* select */

.select {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    padding: 8px;

    border-radius: 4px;
    border: thin solid #CCC;

    background-color: white;
    color: black;

    line-height: 1;

    transition:
        color .1s,
        background-color .1s,
        border-color .1s;
}

/* :hover */

.htmlSelect:hover + .select {
    border-color: #999;
}

/* :focus */

.htmlSelect:focus + .select {
    border-color: #4C739E;
}

/* :disabled */

.htmlSelect:disabled + .select {
    background-color: #E6E6E6;
    border-color: #E6E6E6;
    color: #808080;
}

.ReadonlySelect .select {
    border-color: #E6E6E6 !important;
    background-color: white !important;
    color: black !important;
}

/* icon */

.icon {
    pointer-events: none;
    position: absolute;
    height: 20px;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
}
