/* table */

.table {
    border-collapse: collapse;
    white-space: nowrap;
    font-size: 14px;
}

.table tr {
    cursor: pointer;
}

.table td {
    /* border: thin solid black; */
    padding: 4px 16px;
    height: 32px;
}

/* firstRow */

.firstRow td {
    background-color: #F5F4F0;
    transition: background-color .2s;
}

.firstRow:hover td,
.firstRow:has(+ *:hover) td,
.firstRow:has(+ * + *:hover) td,
.firstRow:has(+ * + * + *:hover) td {
    background-color: #E2E1DE;
}

.firstRow:active td,
.firstRow:has(+ *:active) td,
.firstRow:has(+ * + *:active) td,
.firstRow:has(+ * + * + *:active) td {
    background-color: #CBCAC8;
}

.selectedFirstRow {
    composes: firstRow;
}

.selectedFirstRow td:not(:last-child) {
    background-color: #A3C57F !important;
    color: #3A5470 !important;
}

/* notFirstRow */

.notFirstRow {
    font-style: italic;
}

/* group */

.group {
    padding: 4px 8px !important;
}

/* company */

.company {
    font-weight: bold;
}

/* country */

.country {
    font-weight: bold;
    font-style: italic;
}

/* directionIcon */

.directionIcon {
    position: relative;
    left: -.5px;

    display: flex;
    align-items: center;
    justify-content: center;
}

/* status */

.status {
    text-decoration: underline;
}

.doneStatus {
    composes: status;
    color: red;
}

.declinedStatus {
    composes: status;
    color: gray;
}

/* roleArrow */

.roleArrow {
    background-color: transparent !important;
    padding-left: 4px !important;
}

/* roleAbbr */

.roleAbbr {
    font-size: 18px;
    font-style: normal;
}

/* client */

.client {
    background-color: #e6f5fc;
}

/* agent */

.agent {
    background-color: #E8F9E0;
}

/* document */

.documents {
    background-color: rgb(218, 188, 104, 30%);
}

/* gap */

.gap {
    height: 16px !important;
}
