/* Chat */

.Chat {
    position: relative;

    width: 100%;
    height: 100%;

    border-radius: 4px;

    display: grid;
    grid-template-columns: auto;
    grid-template-rows: min-content auto min-content;
}

/* header */

.header {
    grid-row: 1;
    grid-column: 1;

    color: #2C5282;

    font-size: 22px;

    padding: 8px;

    border-bottom: thin solid #F3EAD1;
}

/* allMessagesWrapper */

.allMessagesWrapper {
    grid-row: 2;
    grid-column: 1;

    width: 100%;
    position: relative;
}

/* gap */

.gap {
    margin-top: auto;
}

/* scrollDownButton */

.scrollDownButton {
    position: absolute;
    right: 16px;
    bottom: 16px;

    transition: opacity .1s;
}

/* allMessages */

.allMessages {
    position: absolute;

    width: 100%;
    height: 100%;

    overflow-y: auto;

    display: flex;
    flex-direction: column;
    align-items: stretch;

    padding: 8px 16px;
}

/* message */

.message {
    font-size: 14px;

    width: 100%;
    padding: 4px;

    border-radius: 4px;

    display: flex;
    flex-direction: column;
}

.message.local {
    margin-left: auto;
    margin-right: 0;

    align-items: flex-end;
}

.message.selected {
    background-color: rgba(45, 111, 204, .15);
}

/* messageSender */

.message.local .messageSender {
    text-align: end;
}

/* messageBody */

.messageBody {
    position: relative;

    border-radius: 0 4px 4px 4px;

    max-width: 90%;
    width: fit-content;
    padding: 8px;

    background-color: #EAEBEA;

    overflow-x: auto;

    transition:
        background-color .1s,
        border-color .1s;
}

.message.local .messageBody {
    background-color: #e1fec8;

    border: thin solid #EAEBEA;
    border-radius: 4px 0 4px 4px;
}

.message.error.local .messageBody {
    border: thin solid #D11313;
    background-color: #ffe1e1;
    color: #D11313;
}

.message.error:not(.local) .messageBody {
    background-color: #D11313;
    color: white;
}

/* messageText */

.messageText {
    white-space: pre-wrap;
    word-break: break-all;
}

/* messageDate */

.messageDate {
    color: #666;

    font-size: 12px;
    text-align: center;

    margin: 8px;
}

/* messageStatus */

.messageStatus {
    margin-left: auto;
}

/* messageEditedMark */

.messageEditedMark {
    color: #888;
    font-size: 10px;
}

/* messageTime */

.messageTime {
    color: #888;
    font-size: 10px;
}

/* inputs */

.inputs {
    grid-row: 3;
    grid-column: 1;

    padding: 8px;

    max-height: 400px;
    overflow-y: auto;

    border-top: thin solid #F3EAD1;
}
