/* table */

.table th,
.table td {
    padding: 8px;
}

.table th {
    padding-left: 0;
    font-weight: normal;
    text-align: left;
}

/* text */

.text {
    padding: 16px;
    padding-left: 0;
}
