/* container */

.container {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    pointer-events: none;
}

/* menu */

.menu {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;

    background-color: white;

    border-radius: 4px;

    pointer-events: all;
}
