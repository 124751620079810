.MenuButton {
    position: relative;

    width: 24px;
    height: 24px;

    aspect-ratio: 1/1;

    cursor: pointer;
}

.MenuButton::after {
    position: absolute;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;

    content: ".";
    color: transparent;

    mix-blend-mode: screen;

    background: #305670;

    animation-name: glow;
    animation-duration: 30s;
    animation-delay: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes glow {
    0% {
        background: linear-gradient(90deg, #305670 20%, #ffd700 50%, #305670 80%);
        background-repeat: no-repeat;
        background-color: #305670;
        background-position: -24px;
    }

    3% {
        background: linear-gradient(90deg, #305670 20%, #ffd700 50%, #305670 80%);
        background-repeat: no-repeat;
        background-color: #305670;
        background-position-x: 24px;
    }

    4% {
        background: #305670;
        background-position-x: 24px;
    }
}
