.Information {
    font-size: small;
}

.Information-regular {
    composes: Information;
    color: black;
}

.Information-error {
    composes: Information;
    color: red;
}

.Information-warning {
    composes: Information;
    color: orange;
}

.Information-success {
    composes: Information;
    color: lime;
}
