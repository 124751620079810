.Badge {
    border: thin solid black;
    border-radius: 16px;

    background-color: white;
    color: black;

    padding: 4px;

    font-size: 12px;
    white-space: nowrap;
}
