/* table */

.table {
    font-size: 14px;
    border-collapse: collapse;
}

.table td,
.table th {
    /* border: thin solid black; */
    padding: 4px 16px;
    font-style: italic;
    font-weight: normal;
}

.table th {
    text-align: left;
}

/* background */

.background {
    background-color: #E8F9E0;
}

/* missingValue */

.missingValue {
    color: #666;
}

/* name */

.name {
    composes: background;
    font-weight: bold !important;
}

.unnamed {
    composes: name, missingValue;
}

/* input */

.input {
    min-width: 350px;
}

.input * {
    font-size: 14px !important;
    cursor: auto !important;
}

/* gap */

.gap {
    height: 16px !important;
}
