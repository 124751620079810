/* Uploading */

.Uploading {
    color: black;

    display: grid;
    grid-template-columns: repeat(3, min-content);
    grid-template-rows: repeat(2, min-content);
    align-items: baseline;
    column-gap: 8px;

    cursor: wait;
}

/* SuccessfullyUploaded */

.SuccessfullyUploaded {
    position: relative;

    background-color: white;
    color: black;

    border: thin solid #94AFCD;
    border-radius: 4px;

    display: flex;
    align-items: center;
    gap: 8px;

    width: fit-content;
    height: 40px;
    padding: 8px;

    cursor: pointer;

    line-height: 1;
}

/* FailedUploading */

.FailedUploading {
    composes: SuccessfullyUploaded;
    border: thin solid #E53E3E;
}

/* Removed */

.Removed {
    display: none;
}

/* Loading */

.Loading {
    position: relative;

    color: black;

    border-radius: 4px;

    width: 200px;
    height: 40px;
}

/* FailedLoading */

.FailedLoading {
    composes: Loading;

    cursor: default;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(229, 62, 62, 10%);
}

/* info */

.info {
    display: flex;
    align-items: center;
    gap: 8px;
}

/* filename */

.filename {
    max-width: 200px;

    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.Uploading .filename {
    grid-column: 1;
    grid-row: 1;
}

/* progressBar */

.Uploading .progressBar {
    grid-column: 1/3;
    grid-row: 2;
}

/* progressPercents */

.progressPercents {
    font-size: 12px;
    color: #3B5A7E;
}

.Uploading .progressPercents {
    grid-column: 2;
    grid-row: 1;
}

/* cancel */

.Uploading .cancel {
    grid-column: 3;
    grid-row: 1/3;
}
