/* stopwatch */

.stopwatch {
    font-size: 64px;
}

/* text */

.text {
    white-space: pre;
    text-align: center;
}
