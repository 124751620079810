/* SectionLink */

.SectionLink {
    position: relative;

    display: inline-flex;
    align-items: center;
    gap: 8px;

    width: 100%;

    padding: 2px 4px;

    font-size: 18px;
    text-decoration: none;
    white-space: nowrap;

    color: #305673;

    border: thin solid transparent;

    transition: all .4s;
}

.CurrentSectionLink {
    composes: SectionLink;

    border-radius: 8px;
    border-color: #305673;
}

/* icon */

.icon {
    border: thin solid #305673;

    aspect-ratio: 1/1;

    padding: 4px;

    display: flex;
    align-items: center;
    justify-content: center;
}

/* text */

.text {
    display: inline-flex;
    align-items: center;

    width: 100%;
    height: 32px;

    padding: 0 8px;

    animation-duration: .4s;
    animation-fill-mode: forwards;
}

.appearingText {
    composes: text;
    animation-name: appear;
}

.hidingText {
    composes: text;
    animation-name: hide;
}

/* currentIndicator */

.currentIndicator {
    position: absolute;
    top: 50%;
    right: 4px;
    transform: translateY(-50%);
}

/* appear */

@keyframes appear {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* hide */

@keyframes hide {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
