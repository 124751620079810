/* MoneyInput */

.MoneyInput {
    width: 100%;
}

/* inputContainer */

.inputContainer {
    width: 100%;
    display: flex;
}

.inputContainer > * {
    flex-shrink: 0;
}

/* amountInputContainer */

.amountInputContainer {
    width: calc(100% - 108px);
    margin-left: 8px;
}
