/* TabbedView */

.TabbedView {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 16px;
}

.TabbedView > :nth-child(2) {
    flex-grow: 1;
}

/* top */

.top {
    display: flex;
    gap: 32px;

    overflow: auto;
}

/* header */

.header {
    display: flex;
}

.header > :not(:first-child) {
    margin-left: -8px;
}

/* tab */

.tab {
    display: flex;
    justify-content: center;
    gap: 8px;

    width: 100%;

    padding: 8px 16px;

    cursor: default;

    font-size: 16px;
    white-space: nowrap;

    background-color: #D5D5D5;
    color: white;

    border: thin solid #00A2FF;
    border-radius: 4px;

    transition: background-color .2s;
}

.tab:hover {
    background-color: #95c4e1;
}

.selectedTab {
    composes: tab;

    background-color: #00A2FF !important;
    border-color: #EE220C;
}
