/* label */

.label {
    position: relative;
    display: inline-flex;
    align-items: center;
    gap: 8px;
}

/* :readonly */

.readonlyLabel {
    composes: label;
}

.readonlyLabel, .readonlyLabel * {
    cursor: not-allowed;
}

/* :loading */

.loadingLabel {
    composes: readonlyLabel;
}

.loadingLabel, .loadingLabel * {
    cursor: wait;
}

/* htmlInput */

.htmlInput {
    width: 10px;
    height: 20px;
    transform: scaleX(2);
    margin: 0 5px;
    opacity: 0;
}

/* input */

.input {
    position: absolute;
    left: 0;
    top: 0;

    width: 20px;
    height: 20px;

    border: thin solid #CCC;
    border-radius: 50%;

    pointer-events: none;

    transition:
        color .1s,
        background-color .1s,
        border-color .1s;
}

/* :hover */

.htmlInput:hover + .input {
    border-color: #999;
}

/* :checked */

.htmlInput:checked + .input {
    border-color: #02517A;
}

.htmlInput:checked + .input::after {
    content: " ";

    position: absolute;
    left: 4px;
    top: 4px;

    width: 10px;
    height: 10px;

    border-radius: 50%;
    background-color: #02517A;
}

/* :disabled */

.htmlInput:disabled ~ * {
    color: #808080;
}

.htmlInput:disabled + .input {
    background-color: #E6E6E6;
    border-color: #E6E6E6
}

.htmlInput:disabled:checked + .input::after {
    background-color: #E6E6E6;
}

/* :readonly */

.readonlyLabel .input {
    background-color: transparent !important;
    border-color: #E6E6E6;
}

.readonlyLabel .htmlInput:checked + .input::after {
    background-color: #94AFCD;
}
