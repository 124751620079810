/* logo */

.logo {
    font-size: 10rem;
}

/* langSelector */

.langSelector {
    position: absolute;
    left: 16px;
    bottom: 16px;
}

/* contactsLink */

/* .contactsLink {
    position: absolute;
    right: 16px;
    bottom: 16px;
} */
