/* Input */

.Input {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 4px;

    font-size: 16px;
    line-height: 1;
}

/* readonly */

.readonly, .readonly * {
    cursor: not-allowed;
}

/* loading */

.loading, .loading * {
    cursor: wait;
}

/* htmlInputContainer */

.htmlInputContainer {
    position: relative;
    width: 100%;
}

/* htmlInput */

.htmlInput {
    width: 100%;
    height: 32px;

    padding: 8px;

    border-radius: 4px;
    border: thin solid #CCC;

    font-size: inherit;

    background-color: white;
    color: black;

    transition:
        color .1s,
        background-color .1s,
        border-color .1s;
}

.Input:hover .htmlInput:not(:focus):not(:disabled) {
    border-color: #999;
}

.htmlInput:focus {
    border-color: #4C739E;
    outline: none;
}

.htmlInput:disabled {
    background-color: #E6E6E6;
    border-color: #E6E6E6;
    color: #808080;
}

.readonly .htmlInput {
    background-color: white;
    border-color: #E6E6E6;
    color: black;
}

.invalid .htmlInput {
    border: thin solid red !important;
}

/* left */

.left {
    position: absolute;
    left: -4px;
    top: 50%;
    transform: translate(-100%, -50%);
}

/* right */

.right {
    position: absolute;
    right: -4px;
    top: 50%;
    transform: translate(100%, -50%);
}

/* over */

.over {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
}

/* icons */

.icons {
    position: absolute;
    top: 8px;
    right: 8px;

    display: flex;
    align-items: center;
    gap: 4px;
}
