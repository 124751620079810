/* Arrow */

.SticklessArrow {
    width: 0;
    height: 0;

    font-size: 16px;

    border-top: 1em solid transparent;
    border-bottom: 1em solid transparent;
    border-left: 1em solid black;
}

/* ArrowWithStick */

.ArrowWithStick {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0;

    font-size: 16px;
}

/* stick */

.stick {
    width: 1em;
    height: 1em;

    background-color: black;
}
