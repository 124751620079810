/* tableWrapper */

.tableWrapper {
    width: 100%;
    overflow-x: auto;
}

/* table */

.table {
    min-width: 700px;
    width: 100%;

    text-align: left;
    border-collapse: collapse;
    table-layout: fixed;
}

/* cell*/

.cell {
    font-size: 12px;
    padding: 8px;
    border: thin solid #D5DDE3;
    vertical-align: top;
}

/* weekDayName */

.weekDayName {
    composes: cell;
    color: #999;
}

/* day */

.day {
    composes: cell;
}

/* anotherMonthDay */

.anotherMonthDay {
    composes: day;
    opacity: 40%;
}

/* dayDate */

.dayDate {
    color: #999;

    text-align: right;
    white-space: nowrap;
}

.weekend .dayDate {
    color: red;
}

/* transfers */

.transfers {
    margin-top: 8px;
    min-height: 80px;
}
