.Group {
    width: 100%;

    padding: 8px;

    background-color: #F9FAFB;

    border: thin solid #A0AEC0;
    border-radius: 4px;
}
