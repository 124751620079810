/* table */

.table {
    border-collapse: collapse;
    white-space: nowrap;
    font-size: 14px;
}

.table td {
    /* border: thin solid black; */
    padding: 0 16px;
    height: 48px;
}

.table tr {
    cursor: pointer;
}

/* firstRow */

.firstRow {
    font-size: 16px;
    transition: background-color .2s;
}

.firstRow td:not(.noBackground) {
    background-color: #F5F4F0;
}

.firstRow:hover td:not(.noBackground),
.firstRow:has(+ *:hover) td:not(.noBackground),
.firstRow:has(+ * + *:hover) td:not(.noBackground) {
    background-color: #E2E1DE;
}

.firstRow:active td:not(.noBackground),
.firstRow:has(+ *:active) td:not(.noBackground),
.firstRow:has(+ * + *:active) td:not(.noBackground) {
    background-color: #CBCAC8;
}

/* bigText */

.bigText {
    font-size: 18px;
}

/* client */

.client {
    background-color: #e6f5fc;
}

/* agent */

.agent {
    background-color: #E8F9E0;
}

/* payed */

.payed {
    color: green;
}

/* inWork */

.inWork {
    color: red;
}

/* gap */

.gap {
    height: 16px !important;
    cursor: default !important;
}
