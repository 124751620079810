/* ChatCard */

.ChatCard {
    display: grid;
    grid-template-columns: auto min-content;
    grid-template-rows: 1fr 1fr;
    gap: 4px;

    cursor: default;

    width: 100%;
    padding: 8px;

    border-radius: 4px;

    background-color: rgba(0, 0, 0, .05);
    color: black;

    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;

    transition: background-color .1s;
}

.ChatCard:hover {
    background-color: #50819f;
    color: white;
}

.SelectedChatCard {
    composes: ChatCard;
}

.SelectedChatCard,
.SelectedChatCard:hover {
    background-color:#32658C;
    color: white
}

.ChatCard:active {
    background-color: #26455C;
    color: white;
}

/* title */

.title {
    grid-column: 1;
    grid-row: 1;

    overflow: hidden;
    text-overflow: ellipsis;
}

/* lastMessageMoment */

.lastMessageMoment {
    grid-column: 2;
    grid-row: 1;

    justify-self: end;

    color: #666;

    transition: color .1s;
}

.ChatCard:hover .lastMessageMoment,
.SelectedChatCard .lastMessageMoment {
    color: #CCC;
}

/* lastMessagePreview */

.lastMessagePreview {
    grid-column: 1;
    grid-row: 2;

    display: flex;
    align-items: center;
    gap: 4px;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    color: #666;

    transition: color .1s;
}

.ChatCard:hover .lastMessagePreview,
.SelectedChatCard .lastMessagePreview {
    color: #CCC;
}

/* sender */

.sender {
    color: black;

    transition: color .1s;
}

.ChatCard:hover .sender,
.SelectedChatCard .sender {
    color: white;
}

/* document */

.document {
    color: #2D6FCC;

    transition: color .1s;
}

.ChatCard:hover .document,
.SelectedChatCard .document {
    color: #bdd8ff;
}

/* unreadCount */

.unreadCount {
    grid-column: 2;
    grid-row: 2;

    justify-self: end;
}
