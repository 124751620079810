/* fields */

.fields {
    width: 100%;

    display: grid;
    grid-template-columns: min-content minmax(300px, 1fr) max-content minmax(300px, 1fr);
    grid-auto-rows: min-content;
    gap: 16px;

    margin-bottom: 48px;
}

/* label */

.label {
    padding-top: 8px;
}

/* column1Label */

.column1Label {
    grid-column: 1;
    composes: label;
}

/* column3Label */

.column3Label {
    composes: label;
    grid-column: 3;
}

/* value */

/* .value {} */

/* column2Value */

.column2Value {
    /* composes: value; */
    grid-column: 2;
}

/* column4Value */

.column4Value {
    /* composes: value; */
    grid-column: 4;
}

/* column1To5Value */

.column1To4Value {
    /* composes: value; */
    grid-column: 1/5;
}

/* column2To4Value */

.column2To4Value {
    /* composes: value; */
    grid-column: 2/5;
}

/* subheader */

.subheader {
    grid-column: 1/5;
}

/* direction */

.direction {
    composes: column2To4Value;
    align-self: center;
}

/* decimalOutput */

.decimalOutput {
    width: fit-content;
}
