/* nonCritical */

.Button-fill-nonCritical {
    composes: Button from "./base.module.css";

    background-color: #305673;
    color: white;
}

.Button-fill-nonCritical img {
    filter: brightness(0) invert(100%);
}

/* :hover */

.Button-fill-nonCritical:hover {
    background-color: #003f60;
}

/* :active */

.Button-fill-nonCritical:active {
    background-color: #01314b;
}

/* critical */

.Button-fill-critical {
    composes: Button from "./base.module.css";

    background-color: #D11313;
    color: white;
}

.Button-fill-critical img {
    filter: brightness(0) invert(100%);
}

/* :hover */

.Button-fill-critical:hover {
    background-color: #A70F0F;
}

/* :active */

.Button-fill-critical:active {
    background-color: #8B0E0E;
}

/* common */

/* :disabled */

.Button-fill-nonCritical:disabled,
.Button-fill-critical:disabled {
    background-color: #99B9C9;
    color: #E1F0F9;
}

.Button-fill-nonCritical img,
.Button-fill-critical img {
    filter: brightness(0) saturate(100%) invert(84%) sepia(35%) saturate(87%) hue-rotate(168deg) brightness(106%) contrast(95%);
}
