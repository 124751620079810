/* ColorLegend */

.ColorLegend {
    width: fit-content;

    display: inline-flex;
    align-items: center;
    gap: 8px;
}

/* clickable */

.clickable {
    cursor: default;
}

/* color */

.color {
    width: calc(.40em);
    height: calc(.80em);

    border-radius: 4px;
    background-color: white;

    border: thin solid transparent;

    transition:
        background-color .1s,
        border-color .1s;
}

/* legend */

.legend {
    white-space: nowrap;
    color: black;
    transition: color .1s;
}

.clickable:hover .legend {
    color: #007EC6;
}

.clickable:active .legend {
    color: #44B8FB;
}

.active .legend {
    color: #00A2FF !important;
}
