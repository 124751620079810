/* Sidebar */

.Sidebar {
    z-index: 10;
}

.Sidebar * {
    z-index: inherit;
}

/* content */

.content {
    position: fixed;
    left: 0;
    top: 0;

    height: 100%;
    overflow-y: auto;
    padding: 16px;

    background-color: #2E4E63;
}

/* openAnimation */

.openAnimation {
    animation-name: open;
    animation-duration: .1s;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
}

@keyframes open {
    from {
        left: -100%;
    }

    to {
        left: 0;
    }
}

/* closeAnimation */

.closeAnimation {
    animation-name: close;
    animation-duration: .1s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
}

@keyframes close {
    from {
        left: 0;
    }

    to {
        left: -100%;
    }
}
