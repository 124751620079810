/* Carousel */

.Carousel {
    display: flex;
    align-items: stretch;
    gap: 8px;

    background-color: #EEE;

    width: 100%;
    height: 48px;

    padding: 8px;

    cursor: default;
}

/* item */

.item {
    flex-grow: 1;
    font-size: 14px;
}
