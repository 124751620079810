.Dim {
    left: 0;
    top: 0;

    transform: none;

    transition: background-color .1s;
}

.AbsoluteDim {
    composes: Dim;

    position: absolute;

    width: 100%;
    height: 100%;
}

.FixedDim {
    composes: Dim;

    position: fixed;

    width: 100vw;
    height: 100vh;
}
