/* NotificationList */

.NotificationList {
    display: flex;
    flex-direction: column;

    background-color: #EEE;
    color: black;

    width: 100%;

    border-radius: 4px;
}

.NotificationList > * {
    padding: 8px 16px;
}

/* header */

.header {
    background-color: white;

    font-size: 18px;

    border-bottom: thin solid #7F969E;
    border-radius: 4px 4px 0 0;
}

/* content */

.content {
    flex-grow: 1;
    overflow-y: auto;
}

/* footer */

.footer {
    background-color: white;
    border-top: thin solid #7F969E;
    border-radius: 0 0 4px 4px;
}
