/* role */

.role {
    font-size: 26px;

    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
}

/* :hidden */

.hiddenRole {
    display: none;
}

/* :hiding */

.hidingRole {
    composes: role;

    width: 0;

    animation-name: hiding;
    animation-duration: .2s;
}

@keyframes hiding {
    to {
        opacity: 0;
    }
}

/* :appearing */

.appearingRole {
    composes: role;

    animation-name: appearing;
    animation-duration: .4s;
}

@keyframes appearing {
    from {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* title */

.title {
    position: relative;
    font-size: 20px;
    color: #305673;
}
