/* Pane */

.Pane {
    overflow: hidden;
    border-radius: 4px;
    background-color: #F5F4F0;

    display: flex;
    flex-direction: column;
}

/* part */

.part {
    position: relative;
    overflow: auto;
    padding: 20px;
    font-size: 16px;
}

/* header */

.header {
    composes: part;

    border-bottom: thin solid lightgray;
    color: #305673;

    white-space: nowrap;
}

/* content */

.content {
    composes: part;
    flex-grow: 1;
}
