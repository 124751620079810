/* Info */

.Info {
    width: 100%;

    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;

    white-space: nowrap;
}

/* dateAndUserVariant */

.dateAndUserVariant {
    grid-column: 1;
    grid-row: 1;

    overflow: hidden;
    text-overflow: ellipsis;
}
