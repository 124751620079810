/* YearMonth */

.YearMonth {
    border-collapse: collapse;
    cursor: default;
}

/* monthName */

.monthName {
    color: #111;
}

/* weekDayName */

.weekDayName {
    font-weight: normal;
    font-size: 14px;

    padding: 8px;

    border-bottom: thin solid #D6D6D6;

    color: #333;
}

/* day */

.day {
    position: relative;
    z-index: 1;

    text-align: center;

    padding: 8px;
}

.day::before {
    content: ".";

    position: absolute;
    top: 4px;
    left: 4px;
    z-index: -1;

    width: calc(100% - 8px);
    height: calc(100% - 8px);

    border-radius: 4px;

    color: transparent;

    transition: background-color .1s;
}

.day:hover::before {
    background-color: #DDD;
}

.day:active::before {
    background-color: #BBB;
}

.day-prev {
    composes: day;
    opacity: 40%;
}

.day-cur {
    composes: day;
    color: #333;
}

.day-next {
    composes: day;
    opacity: 40%;
}

.weekend {
    color: red;
}

/* transfer */

.transfer.day::before {
    background-color: rgba(0, 255, 0, 40%);
}

.transfer.day:hover::before {
    background-color: rgba(0, 255, 0, 60%);
}

.transfer.day:active::before {
    background-color: rgb(0, 255, 0);
}
