/* Modal */

.Modal {
    margin: 0 !important;
    padding: 0 !important;

    position: fixed;
    left: 0;
    top: 0;

    width: 100vw;
    height: 100vh;

    z-index: 5;
}

/* container */

.container {
    position: relative;

    background-color: white;

    min-width: 20px;
    min-height: 20px;

    border-radius: 4px;

    animation-name: appear;
    animation-duration: .25s;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
}

@keyframes appear {
    from {
        top: 100vh;
    }

    to {
        top: 0;
    }
}

/* header */

.header {
    border-bottom: thin solid #CCD5E1;

    padding: 8px;
}

/* headerText */

.headerText {
    font-size: 18px;

    white-space: nowrap;

    color: #3B5A7E;
}

/* content */

.content {
    position: relative;
    padding: 16px;
}

/* buttons */

.buttons {
    border-top: thin solid #CCD5E1;
    padding: 8px;
}
