/* table */

.table {
    border-collapse: collapse;

    white-space: nowrap;
    font-size: 14px;
    font-style: italic;

    text-align: left;
}


.table td {
    cursor: pointer;
}

.table td, .table th {
    /* border: thin solid black; */
    padding: 4px 16px;
    height: 32px;
}


.table tr {
    transition: background-color .2s;
}

.table tr:nth-child(2n + 1) {
    background-color: #F5F4F0;
}

.table tr:nth-child(2n) {
    background-color: #E4F9DE;
}

.table tr:not(.header):hover {
    background-color: #E2E1DE;
}

.table tr:not(.header):active {
    background-color: #CBCAC8;
}

/* group */

.group {
    padding: 4px 8px !important;
}
