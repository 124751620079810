.Form {
    width: 100%;
}

.LoadingForm {
    composes: Form;
}

.LoadingForm, .LoadingForm * {
    cursor: wait;
}
