/* NotificationCard */

.NotificationCard {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-auto-rows: min-content;
    align-items: center;
    gap: 8px;

    width: 100%;
    padding: 8px;

    overflow: hidden;

    background-color: white;
    color: black;

    border-radius: 4px;

    cursor: pointer;

    transition: background-color .1s;
}

.NotificationCard:hover {
    background-color: #DDD;
}

/* message */

.message {
    grid-row: 1;
    grid-column: 1;

    word-wrap: break-word;
}

/* delete */

.delete {
    grid-row: 1;
    grid-column: 2;

    margin-left: 8px;

    justify-self: end;
}

/* time */

.time {
    grid-row: 2;
    grid-column: 1/3;

    color: #666;
}

/* emphasis */

.emphasis {
    color: #2D6FCC;
    font-style: normal;
}
