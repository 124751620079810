/* Copy */

.Copy {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

/* iconAndTextContainer */

.iconAndTextContainer {
    display: inline-flex;
    align-items: flex-end;
    gap: 8px;
}

/* text */

.text {
    position: relative;

    top: 1.5px;

    color: #2D6FCC;

    text-decoration: underline;
    text-decoration-color: #2D6FCC;
    white-space: nowrap;

    transition:
        color .1s,
        text-decoration-color .1s;
}

.Copy:hover .text {
    color: #1f4c8b;
    text-decoration-color: #1f4c8b;
}

.Copy:active .text {
    color: #24579f;
    text-decoration-color: #24579f;
}

/* notification */

.notification {
    position: fixed;
    z-index: 1;

    background-color: white;

    border-radius: 4px;

    white-space: nowrap;

    transition: opacity .2s;

    pointer-events: none;
}
