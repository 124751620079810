.Link {
    max-width: 100%;

    display: inline-flex;
    align-items: flex-end;
    gap: 8px;

    text-decoration-color: #2D6FCC;
    color: #2D6FCC;

    transition:
        color .1s,
        text-decoration-color .1s;
}

.Link:hover {
    text-decoration-color: #1f4c8b;
    color: #1f4c8b;
}

.Link:active {
    text-decoration-color: #24579f;
    color: #24579f;
}

.DisabledLink {
    composes: Link;

    color: #999 !important;
    text-decoration-color: #999 !important;

    pointer-events: none;
}
