/* FieldSet */

.FieldSet {
    padding: 20px;
    border-radius: 4px;
    max-width: 340px;
    background-color: white;
    border: 0;
}

/* legend */

.legend {
    float: left;
    width: 100%;

    text-align: center;
    font-size: 2rem;

    margin-bottom: 20px;
}
