/* ExpandButton */

.ExpandButton {
    transition: transform .1s;
    user-select: none;
}

/* collapse */

.collapse {
    transform: scaleY(-1);
}
