/* NotificationCount */

.NotificationCount {
    background-color: red;
    color: white;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    min-width: 1.2em;
    height: 1.2em;

    border-radius: .6em;
}

/* count */

.count {
    height: 1em;
}
