.LoadingIndicator {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: wait;
}

.LoadingIndicator > * {
    border-radius: 50%;
    width: 1em;
    aspect-ratio: 1 / 1;
    cursor: wait;

    animation-name: loading;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

.LoadingIndicator :not(:first-child) {
    margin-left: .5em;
}

.LoadingIndicator :nth-child(2) {
    animation-delay: .25s;
}

.LoadingIndicator :nth-child(3) {
    animation-delay: .5s;
}

@keyframes loading {
    from {
        background-color: black;
    }

    to {
        background-color: transparent;
    }
}
