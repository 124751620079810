/* nonCritical */

.Button-outline-nonCritical {
    composes: Button-text-nonCritical from "./text.module.css";

    border-color: #305673;
}

/* critical */

.Button-outline-critical {
    composes: Button-text-critical from "./text.module.css";

    border-color: #D11313;
}

/* :hover */

.Button-outline-critical:hover {
    border-color: #A70F0F;
}

/* :active */

.Button-outline-critical:active {
    border-color: #D11313;
}

/* common*/

/* :disabled */

.Button-outline-nonCritical:disabled,
.Button-outline-critical:disabled {
    border-color: #CBD5E0;
}
