/* table */

.table {
    font-size: 14px;
    border-collapse: collapse;
}

.table td {
    /* border: thin solid black; */
    padding: 0 16px;
    height: 48px;
}

/* topText */

.topText {
    vertical-align: top;
    position: relative;
    top: 16px;
}

/* name */

.name {
    position: relative;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
}

/* gap */

.gap {
    height: 16px;
}
