/* Segue */

.Segue {
    position: relative;
    overflow: hidden;
    width: 100%;
}

/* children */

.children {
    position: absolute;
    left: 0;
    top: 0;

    display: flex;

    width: 1000000000px;
    height: 100%;
}
