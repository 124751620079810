/* CountDataRow */

.CountDataRow {
    width: 100%;
    font-style: italic;
}

/* label */

.label {
    white-space: nowrap;
}
