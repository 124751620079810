.Button {
    border: thin solid transparent;
    border-radius: 4px;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    padding: 8px;

    white-space: nowrap;
    font-size: 16px;

    width: 100%;
    height: 32px;

    transition:
        color .1s,
        background-color .1s;
}

.Button img {
    transition: filter .1s;
}
