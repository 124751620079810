.SmallTransferCard {
    position: relative;

    width: 100%;
    overflow: hidden;

    cursor: default;

    padding: 8px;

    border-radius: 8px;

    background-color: #DEEEF9;

    white-space: nowrap;
    text-overflow: ellipsis;
}

.SmallTransferCard:hover {
    background-color: #C4E1F4;
}

.SmallTransferCard:active {
    background-color: #B8DEF8;
}
