/* layout */

.layout {
    display: grid;
    grid-template-columns: min-content minmax(360px, auto);
    grid-auto-rows: 40px;
    align-items: center;
    gap: 16px;

    width: 100%;
}

/* label */

.label {
    grid-column: 1;
}

/* value */

.value {
    grid-column: 2;
}

/* row */

.row {
    grid-column: 1/3;
}
