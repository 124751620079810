/* layout*/

.layout {
    display: grid;
    grid-template-columns: max-content 1fr max-content 1fr;
    grid-auto-rows: min-content;
    gap: 16px;
}

/* label */

.label {
    padding-top: 8px;
    grid-column: 1;
}

/* date */

.dateLabel {
    composes: label;
}

.dateValue {
    grid-column: 2;
}

/* time */

.timeLabel {
    composes: label;
    grid-column: 3;
}

.timeValue {
    grid-column: 4;
}

/* momentError */

.momentError {
    grid-column: 1/5;
    justify-self: center;
}

/* comment */

.commentLabel {
    composes: label;
}

.commentValue {
    grid-column: 2/5;
}

/* error */

.error {
    grid-column: 1/5;
}
