/* Sticky */

.Sticky {
    width: 100%;
}

/* children */

.children {
    position: relative;

    width: 100%;
    height: 100%;
}
