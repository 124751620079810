/* layout*/

.layout {
    min-width: 650px;
    display: grid;
    grid-template-columns: max-content 1fr max-content 1fr;
    grid-auto-rows: min-content;
    gap: 16px;
}

/* label */

.label {
    padding-top: 8px;
    grid-column: 1;
}

/* value */

.value {
    grid-column: 2/5;
}

/* date */

.dateValue {
    grid-column: 2;
}

/* time */

.timeLabel {
    composes: label;
    grid-column: 3;
}

.timeValue {
    grid-column: 4;
}

/* agentPercent */

.agentPercentValue {
    composes: value;
    max-width: 100px;
}

/* extraAttorneyFee */

.extraAttorneyFee {
    composes: value;
    max-width: 100px;
}

/* error */

.error {
    grid-column: 1/5;
}
