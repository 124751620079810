/* layout*/

.layout {
    display: grid;
    grid-template-columns: min-content auto;
    grid-auto-rows: min-content;
    gap: 16px;
}

/* label */

.label {
    padding-top: 8px;
    grid-column: 1;
}

/* value */

.value {
    grid-column: 2;
}

/* error */

.error {
    grid-column: 1/3;
}
