/* part */

.part {
    font-size: 14px;
    border: thin solid black;
    background-color: #F5F4F0;
}

/* header */

.header {
    composes: part;

    position: relative;
    top: 1px;

    overflow: auto;

    border-radius: 8px 8px 0 0;
    font-weight: bold;
    padding: 8px;
}

/* content */

.content {
    composes: part;

    z-index: 1;

    overflow: auto;
    padding: 8px 16px;
    width: 100%;
    flex-grow: 1;
}
