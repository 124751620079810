/* InviteApplicationTable */

.InviteApplicationTable {
    white-space: nowrap;
    font-size: 14px;
    font-style: italic;

    display: grid;
    grid-template-columns: repeat(4, min-content);
    grid-auto-rows: min-content;
}

.InviteApplicationTable > * {
    padding: 8px;
}

/* background */

.background {
    grid-column: 1/5;
    background-color: #E8F9E0;
}

/* date */

.date {
    grid-column: 1;
}

/* company */

.company {
    grid-column: 2;
    padding: 8px 16px;
}

/* messageTarget */

.messageTarget {
    grid-column: 3;
}

/* name */

.name {
    grid-column: 3/5;
}

/* buttons */

.buttons {
    grid-column: 4;
}

/* gap */

.gap {
    grid-column: 1/5;
}
