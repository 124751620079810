.table th,
.table td {
    padding: 8px;
}

.table th {
    text-align: right;
}

.table td {
    text-align: left;
}
