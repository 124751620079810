/* nonCritical */

.Button-text-nonCritical {
    composes: Button from "./base.module.css";

    background-color: rgba(2, 81, 122, 5%);
    color: #305673;
}

.Button-text-nonCritical img {
    filter: brightness(0) saturate(100%) invert(17%) sepia(26%) saturate(7017%) hue-rotate(182deg) brightness(100%) contrast(98%);
}

/* :hover */

.Button-text-nonCritical:hover {
    background-color: rgba(0, 63, 96, 10%);
}

/* :active */

.Button-text-nonCritical:active {
    background-color: rgba(1, 49, 75, 15%);
}

/* critical */

.Button-text-critical {
    composes: Button from "./base.module.css";

    background-color: rgba(209, 19, 19, 5%);
    color: #D11313;
}

.Button-text-critical img {
    filter: brightness(0) saturate(100%) invert(16%) sepia(68%) saturate(3123%) hue-rotate(345deg) brightness(114%) contrast(113%);
}

/* :hover */

.Button-text-critical:hover {
    background-color: rgba(167, 15, 15, 10%);
    color: #A70F0F
}

.Button-text-critical:hover img {
    filter: brightness(0) saturate(100%) invert(14%) sepia(69%) saturate(2711%) hue-rotate(343deg) brightness(111%) contrast(114%);
}

/* active */

.Button-text-critical:active {
    background-color: rgba(139, 14, 14, 15%);
    color: #D11313;
}

.Button-text-critical:active img {
    filter: brightness(0) saturate(100%) invert(16%) sepia(68%) saturate(3123%) hue-rotate(345deg) brightness(114%) contrast(113%);
}

/* common */

/* :disabled */

.Button-text-nonCritical:disabled,
.Button-text-critical:disabled {
    background-color: #F7FAFE;
    color: #CBD5E0;
}

.Button-text-nonCritical:disabled img,
.Button-text-critical:disabled img {
    filter: brightness(0) saturate(100%) invert(96%) sepia(1%) saturate(4846%) hue-rotate(180deg) brightness(93%) contrast(88%);
}
