/* tableWrapper */

.tableWrapper {
    width: 100%;
    overflow-x: auto;
}

/* table */

.table {
    text-align: left;

    min-width: 800px;
    width: 100%;

    border: thin solid #D6D6D6;
    border-collapse: collapse;

    table-layout: fixed;

    margin-bottom: 24px;
}

/* topLeft */

.topLeft {
    width: 32px;
    border: thin solid #D6D6D6;
}

/* weekDay */

.weekDay {
    font-size: 12px;
    white-space: nowrap;

    padding: 8px;

    border: thin solid #D6D6D6;
}

/* weekDayName */

.weekDayName {
    color: #999;
}

/* hour */

.hour {
    transform: translateY(50%);

    font-size: 8px;
    text-align: center;

    color: #999;
}

/* transfersCell */

.transfersCell {
    border: thin solid #D6D6D6;
    font-size: 12px;
    padding: 4px;
}

/* transfers */

.transfers {
    min-height: 32px;
}
